import { GetPendingModifyRequestsRL } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetRLApprovals = {
  data() {
    return {
      overlay: false,
      GetRLApprovalsList: [],
      noDataText: "",
    };
  },
  methods: {
    async GetRLApprovalsMethod() {
      // try {
      this.overlay = true;
      let result = await API.graphql(
        graphqlOperation(GetPendingModifyRequestsRL, {
          input: {},
        })
      );
      this.GetRLApprovalsList = JSON.parse(
        result.data.GetPendingModifyRequestsRL
      ).message;
      console.warn("GetRLApprovalsList:", this.GetRLApprovalsList);

      this.overlay = false;
      // } catch (error) {
      //   this.overlay = false;
      if (this.GetRLApprovalsList.length == 0)
        this.noDataText = "Currently there are no issuers to list.";
      //   this.GetRLApprovalsList = [];
      // }
    },
  },
};
