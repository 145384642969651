<template>
  <div>
    <Overlay :overlay="overlay" />
    <RLIssuerDetailsDialog
      :dialogRLIssuerDetails="dialogRLIssuerDetails"
      :StoreObj="StoreObj"
      @clicked="RLIssuerDetailsDialogEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="PrimaryFontColor heading1">Pending Approvals of RL</div>
          </v-toolbar>
        </v-col>

        <v-toolbar class="elevation-0">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-text-field
              dense
              outlined
              label="Search Approvals"
              class="FontSize field_label_size field_height borderRadius maxWidthLarge mt-9"
              v-model="search"
            >
              <template v-slot:prepend-inner>
                <v-icon small class="mr-1">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-toolbar>
      </v-row>
      <v-card-text class="pt-0">
        <v-data-table
          :search="search"
          dense
          class="elevation-0"
          :headers="GetRLPendingApprovalsListHeaders"
          :items="GetRLApprovalsList"
          :fixed-header="true"
          :no-data-text="noDataText"
          :hide-default-footer="GetRLApprovalsList.length == 0"
          :footer-props="{
            'items-per-page-options': [100, 200, 300, 400, 500],
          }"
          :items-per-page="100"
          :disable-sort="$vuetify.breakpoint.xs ? true : false"
        >
          <template v-slot:[`item.issuer_name`]="{ item }">
            <div class="FontSize blackAndBoldFont">{{ item.issuer_name }}</div>
          </template>
          <template v-slot:[`item.email_id`]="{ item }">
            <div class="FontSize">{{ item.email_id }}</div>
          </template>
          <template v-slot:[`item.is_verification_request`]="{ item }">
            <div class="FontSize">
              <!-- <v-icon
                small
                class="mr-1"
                :color="
                  item.is_verification_request == true ? 'success' : 'info'
                "
                >{{
                  item.is_verification_request == true
                    ? "mdi-check-decagram"
                    : "mdi-cog"
                }}</v-icon
              > -->
              {{
                item.is_verification_request == true
                  ? "Verification Request"
                  : "Modification Request"
              }}
            </div>
          </template>
          <template v-slot:[`item.Action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="checkItem(item, 'VIEW')"
                  >mdi-eye</v-icon
                >
              </template>
              <span>View Details for {{ item.issuer_name }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";

import { GetRLApprovals } from "../../mixins/GetRLApprovals.js";
import RLIssuerDetailsDialog from "../../components/PendingRLApprovals/Dialogs/RLIssuerDetailsDialog.vue";
export default {
  mixins: [GetRLApprovals],
  components: {
    Overlay,
    RLIssuerDetailsDialog,
  },
  data: () => ({
    search: "",
    overlay: false,

    GetRLPendingApprovalsListHeaders: [
      // { text: "City", value: "city" },
      // { text: "Address 1", value: "issuer_address_1" },
      // { text: "Address 2", value: "issuer_address_2" },
      // { text: "Identification Number", value: "identification_number" },
      // { text: "Postal Code", value: "postal_code" },
      { text: "Issuer Name", value: "issuer_name" },
      { text: "Email ID", value: "email_id" },
      { text: "Request Type", value: "is_verification_request" },

      { text: "Action", value: "Action" },
    ],
    StoreObj: {},
    dialogRLIssuerDetails: false,
  }),
  mounted() {
    this.GetRLApprovalsMethod();
  },
  methods: {
    // IssuerDetailsDialogEmit(Toggle) {
    //   this.dialogIssuerDetails = false;
    //   if (Toggle == 2) {
    //     this.GetRLApprovalsMethod();
    //   }
    // },
    RLIssuerDetailsDialogEmit(Toggle) {
      this.dialogRLIssuerDetails = false;
      if (Toggle == 2) {
        this.GetRLApprovalsMethod();
      }
    },
    checkItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      if (action == "VIEW") {
        this.dialogRLIssuerDetails = true;
      }
    },
  },
};
</script>
<style></style>
